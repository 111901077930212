import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 110,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Evolve Sans, sans-serif",
    secondary: "Font Spring, sans-serif",
    tertiary: "Inter, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#223252",
      light: "#DFE9F2",
      dark: "#021500",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#F9F5FC",
      light: "#5F5F5F",
      dark: "#050D08",
    },
    tertiary: {
      main: "#2C9ED0",
      light: "#F7F7F1",
      dark: "#203252",
    },
    text: {
      primary: "#223252",
      secondary: "#F9F5FC",
      content: "#000000",
      tertiary: "#081107",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Evolve Sans", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
